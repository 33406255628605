<!-- Informe de Expedientes -->

<template>
  <div class="rep_exped">
    <!-- Base Report -->
    <base_R
      v-if="schema"
      :schema="schema"
      :Entorno="Entorno"
      @onEvent="event_capture"
    >
      <template v-slot:ctrls="{}">
        <v-row no-gutters>
          <v-col md="5">
            <v-subheader> Tipo Listado </v-subheader>

            <v-treeview
              :items="$store.state.schemas.report.rep_exped.datos.inf"
              item-key="d"
              item-text="n"
              item-children="c"
              activatable
              dense
              open-on-click
              return-object
              @update:active="change_inf"
            >
            </v-treeview>
          </v-col>

          <v-col md="7">
            <v-select
              v-bind="$select"
              v-model="schema.ctrls.ord.value"
              :label="schema.ctrls.ord.label"
              :items="ord"
              item-value="d"
              item-text="n"
            >
            </v-select>

            <v-select
              v-bind="$select"
              v-model="schema.ctrls.tiposer.value"
              :label="schema.ctrls.tiposer.label"
              :items="$store.state.schemas.report.rep_exped.datos.tiposer"
              item-value="d"
              item-text="n"
            >
            </v-select>

            <v-select
              v-bind="$select"
              v-model="schema.ctrls.estados.value"
              :label="schema.ctrls.estados.label"
              :items="$store.state.schemas.report.rep_exped.datos.estados"
              item-value="d"
              item-text="n"
            >
            </v-select>

            <v-select
              v-bind="$select"
              v-model="schema.ctrls.situacion.value"
              :label="schema.ctrls.situacion.label"
              :items="$store.state.schemas.report.rep_exped.datos.situacion"
              item-value="d"
              item-text="n"
            >
            </v-select>

            <ctrl_multifilterFechas
              tipo="periodo"
              :items="items_multifilterFechas"
              :schema="schema"
            >
            </ctrl_multifilterFechas>

            <v-row no-gutters>
              <v-col md="4">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.poliza.value"
                  :label="schema.ctrls.poliza.label"
                >
                </v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.ramo.value"
                  :label="schema.ctrls.ramo.label"
                >
                </v-text-field>
              </v-col>

              <v-col md="4">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.modalidad.value"
                  :label="schema.ctrls.modalidad.label"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.control.value"
              :label="schema.ctrls.control.label"
            >
            </v-text-field>

            <v-row no-gutters>
              <v-col md="4">
                <v-select
                  v-bind="$select"
                  v-model="schema.ctrls.lugar.value"
                  :label="schema.ctrls.lugar.label"
                  :items="$store.state.schemas.report.rep_exped.datos.lugar"
                  item-value="d"
                  item-text="n"
                >
                </v-select>
              </v-col>

              <v-col md="8">
                <ctrlfinder :schema="schema.ctrls.lugar_loc"> </ctrlfinder>
              </v-col>
            </v-row>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.region.value"
              :label="schema.ctrls.region.label"
            >
            </v-text-field>

            <v-select
              v-bind="$select"
              v-model="schema.ctrls.cierres.value"
              :label="schema.ctrls.cierres.label"
              :items="$store.state.schemas.report.rep_exped.datos.cierres"
              item-value="d"
              item-text="n"
            >
            </v-select>
          </v-col>
        </v-row>
      </template>
    </base_R>
  </div>
</template>

<script>
import { mixinReport } from "@/mixins/mixinReport.js";
import plugs from "@/common/general_plugs";
const base_R = () => plugs.groute("base_R.vue", "base");
const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
const ctrl_multifilterFechas = () =>
  plugs.groute("ctrl_multifilterFechas.vue", "comp");

export default {
  mixins: [mixinReport],
  components: { base_R, ctrlfinder, ctrl_multifilterFechas },
  props: {
    Entorno_F: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.R));
      }
    }
  },

  data() {
    return {
      schema: null,
      Entorno: this.Entorno_F,
      ord: [],
      items_multifilterFechas: [{ ctrl: "fhp", label: "", buscar: "" }]
    };
  },

  methods: {
    async event_capture(evt) {
      console.log("*** onEvent rep_exped. Accion: " + evt.accion + " ***");
      this.acciones_Report(evt);
    },

    // configuro entorno particular
    get_entorno_Report() {
      //this.set_btra();
      //this.set_btraExtra();

      this.Entorno.header.titulo = "Expedientes";
      this.Entorno.report = "rep_exped";
      this.Entorno.header.style += ";min-width:60rem";
    },

    change_inf_fin() {}
  }
};
</script>
